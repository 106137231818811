// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
query GetDossierDetailInformation(
  $dossierId: ID!,
  $dossierTypeSlug: String!,
  $navigationSlug: String!,
) {
  dossierDetailInformation: dossierTypeDetailInformationByDossierTypeSlugAndDossierId(
    dossierId: $dossierId
    dossierTypeSlug: $dossierTypeSlug,
    navigationSlug: $navigationSlug,
  ) {
    blocks {
      id
      name
      location
      label
    }
  }
  tour: tourByDossierTypeSlug(dossierTypeSlug: $dossierTypeSlug) {
    id
    label
    show
    settings
    steps {
      target
      header
      content
      placement
      offset
    }
  }
}
`;

export default QUERY;
